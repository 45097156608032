import React, {useEffect, useState} from "react";
import Btn from "../../components/Btn";
import Abonnement from "../../components/Abonnement";
import {collection, query, where, onSnapshot} from "firebase/firestore";
import {db} from "../../components/Firebase";
import {getCurrentUser} from "../../class/User";
import {
    achatActeUnique,
    getPortalURL,
    resumePayment,
    stripeAbonnement, stripeAnnuleAbonnement,
    stripeDirectGetAbonnement,
    stripeDirectGetSession, stripeRecupererAbonnement
} from "../../class/Abonnement";
import {urlSite} from "../../class/Config";
import {openModal} from "../../components/Modal";
import {Alert} from "react-bootstrap";


export default function MonAbonnement() {
    const [loading, setLoading] = useState(true);
    const [isAbonneUser, setIsAbonneUser] = useState(false);
    const [abonnementId, setAbonnementId] = useState("");
    const [subscriptionId, setSubscriptionId] = useState("");
    const [customerId, setCustomerId] = useState("");
    const [subscription, setSubscription] = useState({});
    const [result, setResult] = useState("");

    useEffect(() => {
        const getData = async () => {
            const urlParams = new URLSearchParams(window.location.search);
            const result = urlParams.get('result');
            setResult(result);
            const session_id = urlParams.get('session_id');
            if (session_id) {
                setLoading(true);
                stripeDirectGetSession(session_id).then(async (dataSession) => {
                    if (dataSession.payment_status === "paid") {
                        stripeDirectGetAbonnement(dataSession.subscription).then(async (data) => {
                            let date_now = Math.floor(Date.now() / 1000);
                            let date_start = data.current_period_start;
                            let date_end = data.current_period_end;
                            if (date_now >= date_start && date_now <= date_end) {
                                setIsAbonneUser(true);
                                setSubscription(data);
                                setAbonnementId(data.plan.id);
                                setCustomerId(data.customer);
                                setSubscriptionId(data.id);
                                setLoading(false);

                                if (data.status === "past_due") {
                                    let last_invoice = data.latest_invoice;
                                    let pi = await resumePayment(last_invoice);
                                    if (pi?.next_action && pi?.status !== "active") {
                                        document.location.href = pi?.next_action?.redirect_to_url?.url;
                                    }
                                }
                            }

                        });
                    }
                });
            }

            let user = await getCurrentUser();
            const q = query(collection(db, "abonnements_periode"), where("uid", "==", user.uid));
            onSnapshot(q, (querySnapshot) => {
                //nombre d'element dans querySnapshot
                if (querySnapshot.size === 0) {
                    setLoading(false);
                } else {

                    querySnapshot.forEach((doc) => {

                        stripeDirectGetAbonnement(doc.data().subscription).then(async (data) => {
                            let date_now = Math.floor(Date.now() / 1000);
                            let date_start = data.current_period_start;
                            let date_end = data.current_period_end;
                            if (date_now >= date_start && date_now <= date_end) {
                                setIsAbonneUser(true);
                                setSubscription(data);
                                setAbonnementId(data.plan.id);
                                setCustomerId(data.customer);
                                setSubscriptionId(data.id);
                                setLoading(false);

                                if (data.status === "past_due") {
                                    let last_invoice = data.latest_invoice;
                                    let pi = await resumePayment(last_invoice);
                                    if (pi?.next_action && pi?.status !== "active") {
                                        document.location.href = pi?.next_action?.redirect_to_url?.url;
                                    }
                                }
                            } else {
                                setLoading(false);
                            }

                        });
                    });
                }
            });

            /*setTimeout(() => {
                setLoading(false);
            },2000)*/


            /*isAbonne(user.uid).then((data) => {
                if(!data.id){
                    setTimeout(() => {
                        setLoading(false);
                    },2000)
                }
                let subscription_id=data.id;

            });*/


            //

        }
        getData();
    }, [])


    return (loading ? <div className={"container d-flex justify-content-center align-content-center"}>
                <div className="spinner-border" role="status"></div>
            </div> :
            <div className={"container-fluid justify-content-center align-items-center d-flex flex-column"}>
                {isAbonneUser ? <Btn className={"mb-3"} iconeDroite={"fleche"} texte={"Créer un nouveau dossier"}
                                     onClick={() => {
                                         document.location.href = "/dashboard/nouvelle-requete"
                                     }} loading={true}/> : null}
                {subscription.status === "active" ? <div className={"texte-standard mb-2 text-center"}>
                    Vous êtes abonné,<br/>
                    Vous pouvez choisir un autre abonnement :
                </div> : null}

                {subscription.status === "past_due" || subscription.status === "unpaid" || subscription.status === "canceled" || subscription.status === "incomplete" || subscription.status === "incomplete_expired" || subscription.status === "trialing" ?
                    <div className="container-fluid alert alert-danger text-center" role="alert">
                        Le paiement n'a pas encore été réceptionné. Si vous avez procédé au règlement via SEPA, il peut
                        y avoir un délai avant la confirmation de réception du paiement (5 jours de traitement en
                        moyenne). Sinon, il se pourrait qu'un problème soit survenu avec vos moyens de paiement. Merci
                        de vous rendre sur votre <a href className={"text-decoration-underline"} onClick={async () => {
                        await getPortalURL(customerId, urlSite + "/dashboard/");
                    }
                    }>espace personnel</a> afin de vérifier vos moyens de paiement et, si nécessaire, procéder à la
                        correction.
                    </div>
                    : null}
                {!isAbonneUser ? <div className={"texte-standard mb-2 text-center"}>
                    Vous n'êtes pas encore abonné,<br/>
                    choisissez un abonnement :
                </div> : null}

                {result==="success"?<Alert className={"mt-4 text-center"}>Il peut s'écouler quelques minutes avant que vos dossiers
                    mensuels
                    soient crédités.<br/>
                </Alert>:null}

                <div className={"d-flex gap-2 mt-4 flex-column flex-lg-row col-12 justify-content-center"}>


                    <div className={"abonnement d-flex justify-content-between flex-column"}>
                        <div className={"abonnementHeader"}>
                            Acte unique
                        </div>
                        <div className={"abonnementOptions d-flex flex-column"}>
                            <ul className={"d-flex gap-2 flex-column justify-content-start align-items-start ps-1"}>
                                <li>Dossier : 1<br/></li>
                            </ul>
                            <Btn className={"my-4 opacity-0"} texte={"En savoir plus"} iconeDroite={"fleche"} onClick={() => {
                                document.location.href = "https://facturesimpayees.com/#tarifs";
                            }}/>
                        </div>
                        <div className={"abonnementBottom d-flex flex-column align-items-center"}>
                            <div>
                                Tarif :
                            </div>
                            <div className={"d-flex"}>
                    <span
                        className={"abonnementTarif"}>49.90</span> €HT/mois
                            </div>

                            <Btn className={"my-4"} texte={"Acheter"} iconeDroite={"fleche"}
                                 onClick={async (endLoading) => {
                                        let url=await achatActeUnique();
                                        if(url) {
                                            document.location.href = url;
                                        }else{
                                            alert("Erreur lors de l'achat.");
                                            endLoading();
                                        }

                                 }} loading={true}/>
                        </div>
                    </div>


                    <Abonnement id={1} current={abonnementId} currentSubscription={subscriptionId}
                                customer={customerId}
                                subscription={subscription} onChange={() => {
                        setLoading(true)
                    }}/>
                    <Abonnement id={2} current={abonnementId} currentSubscription={subscriptionId}
                                customer={customerId}
                                subscription={subscription} onChange={() => {
                        setLoading(true)
                    }}/>
                    <Abonnement id={3} current={abonnementId} currentSubscription={subscriptionId}
                                customer={customerId}
                                subscription={subscription} onChange={() => {
                        setLoading(true)
                    }}/>
                </div>
            </div>

    );
}
