import React, {useEffect} from "react";
import * as Sentry from "@sentry/react";
import {
    BrowserRouter,
    Routes,
    Route,
    useLocation
} from "react-router-dom";
import {analytics} from "./components/Firebase";
import {logEvent} from "firebase/analytics";
/*import ReactGA from 'react-ga';*/

import Inscription from "./pages/Inscription";
import Dashboard from "./pages/Dashboard";
import Connexion from "./pages/Connexion";
import MotDePasseOublie from "./pages/MotDePasseOublie";
import Upload from "./pages/Upload";
import PdfInjonction from "./pages/PdfInjonction";
import PdfMiseEnDemeure from "./pages/PdfMiseEnDemeure";
import PdfAvisReception from "./pages/PdfAvisReception";
import Contact from "./pages/Contact";
import Erreur404 from "./pages/Erreur404";
import CGU from "./pages/CGU";
import CGV from "./pages/CGV";
import PolitiqueViePrivee from "./pages/PolitiqueViePrivee";
import Huissiers from "./pages/Huissiers";
import Token from "./pages/Token";
//import {addEmailWait} from "./class/Email";
import Suivi from "./pages/Suivi";
import Logo from "./pages/Logo";
import PdfRelance from "./pages/PdfRelance";
import Propositions from "./pages/CommissaireDeJustice/Propositions";
import DashboardCommissaireDeJustice from "./pages/CommissaireDeJustice/DashboardCommissaireDeJustice";
import ConnexionCommissaireDeJustice from "./pages/CommissaireDeJustice/ConnexionCommissaireDeJustice";
import InscriptionCommissaireDeJustice from "./pages/CommissaireDeJustice/InscriptionCommissaireDeJustice";
import MotDePasseOublieCommissaireDeJustice from "./pages/CommissaireDeJustice/MotDePasseOublieCommissaireDeJustice";
import DashboardAvocat from "./pages/Avocat/DashboardAvocat";
import InscriptionAvocat from "./pages/Avocat/InscriptionAvocat";
import ConnexionAvocat from "./pages/Avocat/ConnexionAvocat";
import MotDePasseOublieAvocat from "./pages/Avocat/MotDePasseOublieAvocat";
import {Helmet} from "react-helmet";

Sentry.init({
    dsn: "https://0fdd97188aac4d23866104d18c0e00b3@o889197.ingest.sentry.io/4505306195886080",
    integrations: [
        new Sentry.BrowserTracing({
            // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
            tracePropagationTargets: ["localhost:3000", /^https:\/\/facturesimpayees.com/],
        }),
        new Sentry.Replay(),
    ],
    // Performance Monitoring
    tracesSampleRate: 1.0, // Capture 100% of the transactions, reduce in production!
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

//ReactGA.initialize('G-NNB0EMS39R');

/*function logPageView(location) {
    ReactGA.set({page: location.pathname}); // Mise à jour de la page
    ReactGA.pageview(location.pathname); // Enregistrement d'un événement pageview
}*/


function MainApp() {
    const location = useLocation();

    useEffect(() => {
        logEvent(analytics, 'Visited page', {
            page_location: location.pathname,
            page_path: location.pathname,
            page_title: location.pathname
        });
    }, [location]);

    function ProtectedRoute({children}) {
        //const [password, setPassword] = useState("");
        //const [showForm, setShowForm] = useState(false);
        //const [isAccessAllowed, setIsAccessAllowed] = useState(localStorage.getItem("isAccessAllowed") === "true");
        //const [email, setEmail] = useState('');

        /*const checkPassword = (event) => {
            event.preventDefault();
            const correctPassword = "facture42"; // Remplacez "your-password" par le mot de passe souhaité

            if (password === correctPassword) {
                setIsAccessAllowed(true);
                localStorage.setItem("isAccessAllowed", "true");
            } else {
                alert("Mot de passe incorrect");
            }
        };*/

        /*const validateEmail = (email) => {
            const emailPattern = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
            return emailPattern.test(email);
        };*/
        /*const handleKeyDown = (e, submittedEmail) => {
            if (e.key === 'Enter') {
                e.preventDefault();
                if (validateEmail(submittedEmail)) {
                    addEmailWait(submittedEmail);
                    alert("Votre email a bien été enregistré.")
                    setEmail("");
                    //handleEmailSubmit(submittedEmail);
                } else {
                    alert("L'e-mail saisi n'est pas valide");
                    // Vous pouvez également afficher un message d'erreur à l'utilisateur si vous le souhaitez
                }
                //handleEmailSubmit(submittedEmail);
            }
        };*/

        /*if (!isAccessAllowed) {
            return (
                <div className={"wait_bg"}>
                    <div className={"texte_wait"}>Prochainement</div>
                    <div onDoubleClick={() => {
                        //setShowForm(true);
                    }} className={"logo_wait"}></div>
                    <div className={"url_wait"}>facturesimpayees.com</div>
                    <form className={"mail_wait"}>
                        <div>Pour recevoir des informations sur le lancement du site laissez votre adresse email :</div>
                        <input value={email} type={"email"} className={"wait_email"} placeholder={"Adresse email"}
                               onChange={(e) => setEmail(e.target.value)} onKeyDown={(e) => handleKeyDown(e, email)}/>
                    </form>
                    <form onSubmit={checkPassword} className={"wait_form_password"}>
                        <div>Pour accéder à la version Alpha, entrez le mot de passe qui vous a été fourni :</div>
                        <input
                            type="password"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                            placeholder="Entrez le mot de passe"
                        />
                        <button type="submit">Valider</button>
                    </form>
                </div>
            );
        } else {*/
        //logPageView(location);
        return <>{children}</>;
        //}
    }

    const hostname = window.location.hostname;

    if (hostname === "commissairedejustice.localhost" || hostname === "commissairedejustice.facturesimpayees.com") {
        return (
            <>
                {/* Helmet to add Google Tag Manager script on all pages */}
                <Helmet>
                    {/* Google Tag Manager script */}
                    <script async src="https://www.googletagmanager.com/gtag/js?id=G-NNB0EMS39R"></script>
                    <script>{`
                    window.dataLayer = window.dataLayer || [];
                    function gtag(){dataLayer.push(arguments);}
                    gtag('js', new Date());
                    gtag('config', 'G-NNB0EMS39R');
                `}</script>
                    {/* Facebook Pixel script */}
                    <script>{`
                    !function(f,b,e,v,n,t,s)
                    {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
                    n.callMethod.apply(n,arguments):n.queue.push(arguments)};
                    if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
                    n.queue=[];t=b.createElement(e);t.async=!0;
                    t.src=v;s=b.getElementsByTagName(e)[0];
                    s.parentNode.insertBefore(t,s)}(window, document,'script',
                    'https://connect.facebook.net/en_US/fbevents.js');
                    fbq('init', '424328077327129');
                    fbq('track', 'PageView');
                `}</script>
                    <noscript>{`
                    <img height="1" width="1" style="display:none"
                    src="https://www.facebook.com/tr?id=424328077327129&ev=PageView&noscript=1"/>
                `}</noscript>
                </Helmet>
                <Routes>
                    <Route path="/" element={<ProtectedRoute><DashboardCommissaireDeJustice/></ProtectedRoute>}/>
                    <Route path="/dashboard"
                           element={<ProtectedRoute><DashboardCommissaireDeJustice/></ProtectedRoute>}/>
                    <Route path="/connexion"
                           element={<ProtectedRoute><ConnexionCommissaireDeJustice/></ProtectedRoute>}/>
                    <Route path="/inscription"
                           element={<ProtectedRoute><InscriptionCommissaireDeJustice/></ProtectedRoute>}/>
                    <Route path="/mot-de-passe-oublie"
                           element={<ProtectedRoute><MotDePasseOublieCommissaireDeJustice/></ProtectedRoute>}/>
                    <Route path="/dashboard/propositions" element={<ProtectedRoute><DashboardCommissaireDeJustice
                        page={"propositions"}/></ProtectedRoute>}/>
                    <Route path="/dashboard/mes-dossiers" element={<ProtectedRoute><DashboardCommissaireDeJustice
                        page={"mes-dossiers"}/></ProtectedRoute>}/>
                    <Route path="/dashboard/mon-compte"
                           element={<ProtectedRoute><DashboardCommissaireDeJustice
                               page={"mon-compte"}/></ProtectedRoute>}/>
                    <Route path="/dashboard/ma-societe"
                           element={<ProtectedRoute><DashboardCommissaireDeJustice
                               page={"ma-societe"}/></ProtectedRoute>}/>
                </Routes>
            </>
        );
    } else if (hostname === "avocat.localhost" || hostname === "avocat.facturesimpayees.com") {
        return (
            <>
                {/* Helmet to add Google Tag Manager script on all pages */}
                <Helmet>
                    {/* Google Tag Manager script */}
                    <script async src="https://www.googletagmanager.com/gtag/js?id=G-NNB0EMS39R"></script>
                    <script>{`
                    window.dataLayer = window.dataLayer || [];
                    function gtag(){dataLayer.push(arguments);}
                    gtag('js', new Date());
                    gtag('config', 'G-NNB0EMS39R');
                `}</script>
                    {/* Facebook Pixel script */}
                    <script>{`
                    !function(f,b,e,v,n,t,s)
                    {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
                    n.callMethod.apply(n,arguments):n.queue.push(arguments)};
                    if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
                    n.queue=[];t=b.createElement(e);t.async=!0;
                    t.src=v;s=b.getElementsByTagName(e)[0];
                    s.parentNode.insertBefore(t,s)}(window, document,'script',
                    'https://connect.facebook.net/en_US/fbevents.js');
                    fbq('init', '424328077327129');
                    fbq('track', 'PageView');
                `}</script>
                    <noscript>{`
                    <img height="1" width="1" style="display:none"
                    src="https://www.facebook.com/tr?id=424328077327129&ev=PageView&noscript=1"/>
                `}</noscript>
                </Helmet>
                <Routes>
                    <Route path="/" element={<ProtectedRoute><DashboardAvocat page={"propositions"}/></ProtectedRoute>}/>
                    <Route path="/dashboard" element={<ProtectedRoute><DashboardAvocat page={"propositions"}/></ProtectedRoute>}/>
                    <Route path="/connexion" element={<ProtectedRoute><ConnexionAvocat/></ProtectedRoute>}/>
                    <Route path="/inscription" element={<ProtectedRoute><InscriptionAvocat/></ProtectedRoute>}/>
                    <Route path="/mot-de-passe-oublie"
                           element={<ProtectedRoute><MotDePasseOublieAvocat/></ProtectedRoute>}/>
                    <Route path="/dashboard/propositions"
                           element={<ProtectedRoute><DashboardAvocat page={"propositions"}/></ProtectedRoute>}/>
                    <Route path="/dashboard/mes-dossiers"
                           element={<ProtectedRoute><DashboardAvocat page={"mes-dossiers"}/></ProtectedRoute>}/>
                    <Route path="/dashboard/mon-compte"
                           element={<ProtectedRoute><DashboardAvocat page={"mon-compte"}/></ProtectedRoute>}/>
                    <Route path="/dashboard/ma-societe"
                           element={<ProtectedRoute><DashboardAvocat page={"ma-societe"}/></ProtectedRoute>}/>
                </Routes>
            </>
        );
    } else {
        return (
            <>
                {/* Helmet to add Google Tag Manager script on all pages */}
                <Helmet>
                    {/* Google Tag Manager script */}
                    <script async src="https://www.googletagmanager.com/gtag/js?id=G-NNB0EMS39R"></script>
                    <script>{`
                    window.dataLayer = window.dataLayer || [];
                    function gtag(){dataLayer.push(arguments);}
                    gtag('js', new Date());
                    gtag('config', 'G-NNB0EMS39R');
                `}</script>
                    {/* Facebook Pixel script */}
                    <script>{`
                    !function(f,b,e,v,n,t,s)
                    {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
                    n.callMethod.apply(n,arguments):n.queue.push(arguments)};
                    if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
                    n.queue=[];t=b.createElement(e);t.async=!0;
                    t.src=v;s=b.getElementsByTagName(e)[0];
                    s.parentNode.insertBefore(t,s)}(window, document,'script',
                    'https://connect.facebook.net/en_US/fbevents.js');
                    fbq('init', '424328077327129');
                    fbq('track', 'PageView');
                `}</script>
                    <noscript>{`
                    <img height="1" width="1" style="display:none"
                    src="https://www.facebook.com/tr?id=424328077327129&ev=PageView&noscript=1"/>
                `}</noscript>
                </Helmet>
                <Routes>
                    <Route path="/" element={<ProtectedRoute><Dashboard/></ProtectedRoute>}/>
                    <Route path="/connexion" element={<ProtectedRoute><Connexion/></ProtectedRoute>}/>
                    <Route path="/token" element={<ProtectedRoute><Token/></ProtectedRoute>}/>
                    <Route path="/inscription/:emailParrain?"
                           element={<ProtectedRoute><Inscription/></ProtectedRoute>}/>
                    <Route path="/dashboard" element={<ProtectedRoute><Dashboard/></ProtectedRoute>}/>
                    <Route path="/dashboard/mon-compte"
                           element={<ProtectedRoute><Dashboard page={"mon-compte"}/></ProtectedRoute>}/>
                    <Route path="/dashboard/ma-societe"
                           element={<ProtectedRoute><Dashboard page={"ma-societe"}/></ProtectedRoute>}/>
                    <Route path="/dashboard/nouvelle-requete"
                           element={<ProtectedRoute><Dashboard page={"nouvelle-requete"}/></ProtectedRoute>}/>
                    <Route path="/dashboard/requete/:id"
                           element={<ProtectedRoute><Dashboard page={"requete"}/></ProtectedRoute>}/>
                    <Route path="/dashboard/refus/:id"
                           element={<ProtectedRoute><Dashboard page={"refus"}/></ProtectedRoute>}/>
                    <Route path="/dashboard/requete-en-cours"
                           element={<ProtectedRoute><Dashboard page={"requete-en-cours"}/></ProtectedRoute>}/>
                    <Route path="/dashboard/mon-abonnement"
                           element={<ProtectedRoute><Dashboard page={"mon-abonnement"}/></ProtectedRoute>}/>
                    <Route path="/dashboard/mon-abonnement-personnalise"
                           element={<ProtectedRoute><Dashboard
                               page={"mon-abonnement-personnalise"}/></ProtectedRoute>}/>
                    <Route path="/dashboard/mes-moyens-de-paiement"
                           element={<ProtectedRoute><Dashboard page={"mes-moyens-de-paiement"}/></ProtectedRoute>}/>
                    <Route path="/dashboard/parrainnage"
                           element={<ProtectedRoute><Dashboard page={"parrainnage"}/></ProtectedRoute>}/>
                    <Route path="/dashboard/module-de-relance"
                           element={<ProtectedRoute><Dashboard page={"module-de-relance"}/></ProtectedRoute>}/>
                    <Route path="/dashboard/avocats/:id"
                           element={<ProtectedRoute><Dashboard page={"avocats"}/></ProtectedRoute>}/>

                    <Route path="/mot-de-passe-oublie" element={<ProtectedRoute><MotDePasseOublie/></ProtectedRoute>}/>
                    <Route path="/upload/:id" element={<ProtectedRoute><Upload/></ProtectedRoute>}/>
                    <Route path="/demande-injonction/:id" element={<ProtectedRoute><PdfInjonction/></ProtectedRoute>}/>
                    <Route path="/mise-en-demeure/:id" element={<ProtectedRoute><PdfMiseEnDemeure/></ProtectedRoute>}/>
                    <Route path="/relance/:id" element={<ProtectedRoute><PdfRelance/></ProtectedRoute>}/>
                    <Route path="/reception/:id" element={<ProtectedRoute><PdfAvisReception/></ProtectedRoute>}/>
                    <Route path="/contact/" element={<ProtectedRoute><Contact/></ProtectedRoute>}/>
                    <Route path="/cgu/" element={<ProtectedRoute><CGU/></ProtectedRoute>}/>
                    <Route path="/cgv/" element={<ProtectedRoute><CGV/></ProtectedRoute>}/>
                    <Route path="/politique-vie-privee/"
                           element={<ProtectedRoute><PolitiqueViePrivee/></ProtectedRoute>}/>
                    <Route path="/requete-recu/" element={<ProtectedRoute><Huissiers/></ProtectedRoute>}/>
                    <Route path="/suivi/:type/:id" element={<ProtectedRoute><Suivi/></ProtectedRoute>}/>
                    <Route path="/logo/" element={<ProtectedRoute><Logo/></ProtectedRoute>}/>


                    <Route path="*" element={<ProtectedRoute><Erreur404/></ProtectedRoute>}/>
                </Routes>
            </>)
    }
}

export default function App() {


    return (
        <BrowserRouter>
            <MainApp/>
        </BrowserRouter>
    );
}
